<template>
  <router-view />
</template>

<script>
export default {
  name: 'gamelist'
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
